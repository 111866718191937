import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import underthehood from '../assets/images/underthehood.png'
import { SingleStack, CardStacks } from '../components/CardStacks'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import back2future from '../assets/images/back2future.gif'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import '../assets/scss/main.scss'
import '../assets/scss/secondPanel.scss'

import aws from '../assets/layer_pics/aws.png'
import apigateway from '../assets/layer_pics/aws-apigateway.png'
import iam from '../assets/layer_pics/aws-iam.svg'
import lambda from '../assets/layer_pics/aws-lambda.svg'
import azure from '../assets/layer_pics/azure.png'
import docker from '../assets/layer_pics/docker.png'
import dynamo from '../assets/layer_pics/dynamo.png'
import ec2 from '../assets/layer_pics/ec2.png'
import k8 from '../assets/layer_pics/k8.png'
import mongo from '../assets/layer_pics/mongo.png'
import node from '../assets/layer_pics/node.png'
import node2 from '../assets/layer_pics/node2.png'
// import node3 from '../assets/layer_pics/node3.png'
import python from '../assets/layer_pics/python.png'
import ruby from '../assets/layer_pics/ruby.png'
import sql from '../assets/layer_pics/sql.png'
import cloudfront from '../assets/layer_pics/cloudfront.svg'
import arrow5 from '../assets/images/handArrow5.svg'
import SEO from './SEO'
import NavBar from '../components/NavBar'

const useStyles = makeStyles({
  root: {
    width: 300,
  },
})

function Homepage() {
  const siteTitle = 'Brev Framework'
  const classes = useStyles()
  const [email, setEmail] = React.useState('')
  const [open, setOpen] = React.useState(false)

  return (
    <Layout compressed={false}>
      <SEO />
      <NavBar />
      <section id="one" className="main style1">
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <h2 style={{ color: '#6ca7b2' }}>
              A hosted, production-grade developer environment freeing you to
              focus on the server side logic and APIs.
            </h2>
          </Grid>
          {/* Separate the headers so they align even if the heights are different */}
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            style={{ textAlign: 'center' }}
          >
            <Grid item xs={4} style={{ height: '100%' }}>
              <header>
                <h2>Brev</h2>

                <p style={{ fontSize: 'medium' }}>handles ...</p>
              </header>
            </Grid>
            <Grid item xs={4} style={{ height: '100%' }}>
              <header>
                <h2>You</h2>
                <p style={{ fontSize: 'medium' }}>handle ...</p>
              </header>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            style={{ textAlign: 'center' }}
          >
            <Grid item xs={4} style={{ height: '100%' }}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={'logos'}
              >
                <Grid item xs={3}>
                  <img style={{ margin: '7px' }} src={aws} />
                </Grid>
                <Grid item xs={3}>
                  <img style={{ margin: '7px' }} src={ec2} />
                </Grid>
                <Grid item xs={3}>
                  <img style={{ margin: '7px' }} src={cloudfront} />
                </Grid>
              </Grid>
              <h4>infrastructure</h4>
            </Grid>
            <Grid item xs={4} style={{ height: '100%' }}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={'logos'}
              >
                <Grid item xs={3}>
                  <img style={{ margin: '7px' }} src={python} />
                </Grid>
                <Grid item xs={3}>
                  <img style={{ margin: '7px' }} src={node} />
                </Grid>
                <Grid item xs={3}>
                  <img style={{ margin: '7px' }} src={ruby} />
                </Grid>
              </Grid>
              <h4>code</h4>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            style={{ textAlign: 'center' }}
          >
            <Grid item xs={4} style={{ height: '100%' }}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={'logos'}
              >
                <img style={{ margin: '7px' }} src={docker} />
              </Grid>
              <h4>environment</h4>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={'logos'}
              >
                <Grid item xs={3}>
                  <img style={{ margin: '7px' }} src={sql} />
                </Grid>
                <Grid item xs={3}>
                  <img style={{ margin: '7px' }} src={mongo} />
                </Grid>
                <Grid item xs={3}>
                  <img style={{ margin: '7px' }} src={dynamo} />
                </Grid>
              </Grid>
              <h4>database</h4>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={'logos'}
              >
                <Grid item xs={3}>
                  <img style={{ margin: '7px' }} src={apigateway} />
                </Grid>
                <Grid item xs={3}>
                  <img style={{ margin: '7px' }} src={lambda} />
                </Grid>
                <Grid item xs={3}>
                  <img style={{ margin: '7px' }} src={iam} />
                </Grid>
              </Grid>
              <h4>api endpoints</h4>
            </Grid>
            <Grid item xs={4} style={{ height: '100%' }}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={'logos handwriting'}
              >
                <img style={{ margin: '7px' }} src={arrow5} />
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  className={'handwriting'}
                >
                  <Grid item xs={12} style={{ marginLeft: '20px' }}>
                    <Typography variant="h6">That's it!</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginLeft: '20px' }}>
                    <Typography variant="h6">Just code.</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>

      <section id="three" className="main style2 special">
        <div className="grid-wrapper">
          <div className="col-12">
            <header className="major">
              <h2>🙅Avoid Vendor Lockin </h2>
            </header>
            <p>
              We've been there, so we designed Brev from the start with the
              ability to eject. All of the backend/inf code is actually getting
              written and deployed for you behind the scenes, allowing you to
              focus on your product.
            </p>
          </div>

          {/* <div className="col-4">

            <span className="image fit">
              <img src={back2future} alt="" />
            </span>
          </div> */}
        </div>
      </section>

      <section id="waitlist" className="main style1 special">
        <div className="container">
          <header className="major">
            <h2>Get Early Access!</h2>
          </header>
          <p>
            Brev is currently in alpha. We're opening up early access to
            tinkerers and companies looking for a better way.
          </p>

          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={() => {
              setOpen(false)
            }}
          >
            <Alert
              onClose={() => {
                setOpen(false)
              }}
              severity={'success'}
            >
              You're on the waitlist! We'll reach out shortly.
            </Alert>
          </Snackbar>
          <TextField
            id="email"
            label="e-mail"
            variant="outlined"
            value={email}
            onChange={e => {
              setEmail(e.target.value)
            }}
          />

          <ul className="actions uniform">
            <li>
              <a
                className="button special"
                onClick={() => {
                  fetch(
                    `https://lkwz7f.getshortstack.com/api/_execute/ep-565fd8da-38a8-4e28-aac8-474e2eec6da5?email=${email}`,
                    {
                      method: 'GET',
                    }
                  ).then(response => {
                    if (response.ok === true) {
                      setOpen(true)
                      setEmail('')
                    }
                  })
                }}
              >
                Join Waitlist
              </a>
            </li>
          </ul>
          <img src={back2future} alt="" className={'bf'} />
          <p> Real life action shot of using Brev </p>
          <p style={{ fontSize: 'x-small' }}>(plz dont sue us, Universal)</p>
        </div>
      </section>
    </Layout>
  )
}

export default Homepage
